// Loading.js

import React from 'react';

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-dot"></div>
      <h2>Made by: dark.dev</h2>
    </div>
  );
};

export default Loading;