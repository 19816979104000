import React, { useState, useEffect } from 'react';
import './App.css';
import Card from "./components/card.js";
import SideBar from "./components/sidebar.js";
import Loading from "./components/loading.js";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate an async operation
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="mU7Y2Bmsbhe">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <SideBar />
          <Card />
        </>
      )}
    </div>
  );
}

export default App;