import adfwertsdfg from './files/232rgd24123.png'
import dfgdfhertdf from './files/dfsdfasfdfh.png'
import hnasdhah from './files/sdfgdfert.png'
import hdvfghjasdf from './files/dfgdssfdsdf.png'
import star from './files/sfgwerdfgsd.png'
import asdewg from './files/dfwwet.png'
import wsdfert from './files/sdgwewfsd.png';
import axcwerwegs from './files/nnyuyh3y76h.gif';
import a34tfsd from './files/mjyutyhb.png'
import fgertsdfs from './files/weerf34fds.png'
import sdfgwed from './files/erwdcsfs.png'
import sgwesxc from './files/as22dfw.png'
import dfwesdf from './files/hJBGHUISDVFGYU.png'
import cvsdfwe from './files/asd12dffsdf.png'
import wersdfs from './files/ad23we3434hef.png'
import 'balloon-css';
import React, { useState } from 'react';

const Card = () => {
    const [show, setShow] = useState(false);
return (
    <header className="ertegsfw">
    <div className="sdfweasdf">Made By dark.dev</div>
    <div className="etghdfgsw">
    <div className="w3tdgssadf"></div>
    <img src={wsdfert} className="sgwertfgdsf" draggable="false"/>
    <span className="gherswwedf"></span>
    <img src={axcwerwegs} className="a4tyefdgdsvs" draggable="false"></img>
    <div className="star">
    <img src={star} className="img" draggable="false"></img>
    </div>
    <button type="button" onClick={()=>setShow(!show)}className="jklbnwehuikjgbsdfg">
    <p>VER PERFIL</p>
     </button>

    <span className="mnfgbnmdfd"></span>
    <img src={a34tfsd} className="a2rwesdf" draggable="false"></img>
    <div className="nmbhjkfbhjes">
    <span className="ertgdfsac"></span>
    <span className="zxcsdfgsg">dark.dev</span>
    <span className="rtyghsddf">SOBRE MIM</span>
    <span className="ewdfdgsd-sdfee">
      <div className="age">17 yrs old</div>
      <p> </p>
        </span>
        <span className="vbegewrtsdf">
      <p>Software Engineer/Game Developer</p>
      <p>Proficiencies:</p>
      <p>C++/C, C#, HTML/CSS/JS, Java, PY</p>
      <p> </p>
        </span>
    <span className="rtyrterdw">MEMBRO DISCORD DESDE</span>
    <span className="erasxfsdf">nov 14, 2016</span>
    <span className="ergergsdg">JOGANDO</span>
    <img src={cvsdfwe} className="arwersdfs" draggable="false"></img>
    <button className="thrdfgdfgdg" aria-label="Feito por: dark.dev" data-balloon-pos="up"></button>
    <span className="xcvdfgdfger"></span>
    <img src={cvsdfwe} className="erwsfg" draggable="false"></img>
    <button className="zxwsferdf" aria-label="Api: react.js" data-balloon-pos="up"></button>
    <span className="tyhgfhdfe">
      <p>React</p>
      <p> </p>
        </span>
    <span className="werasdfsg">
      <p>Aprendizado em andamento..</p>
      <p>Clique no avatar!</p>
      <p>Feito em React.js</p>
      <p> </p>
        </span>
   <span className="rtgrtfvsd">NOTA</span>
   <textarea placeholder="  Clique para adicionar uma nota" maxlength="55" class="dfgeredfdf"></textarea>
    <div class="wfasdsdvsfg"></div>
    </div>
    <div className="ertgedfsd"></div>
    <img src={fgertsdfs} className="dfghers"></img>
    <button className="trfghjrer" aria-label="HypeSquad Brilliance" data-balloon-pos="up"></button>
    <img src={sdfgwed} className="sdfwerg"></img>
    <button className="ghertersdf" aria-label="Active Developer" data-balloon-pos="up"></button>
    <img src={sgwesxc} className="asdwetg"></img>
    <button className="adfasdaerggeh" aria-label="Assinante Nitro" data-balloon-pos="up"></button>
    <img src={dfwesdf} className="sdgererssdcsfh"></img>
    <button className="tyrhdffwe" aria-label="Impulsionando Servidor" data-balloon-pos="up"></button>
    </div>

    
    <div className="dfghwersdf">
    <div type="button"onClick={()=>setShow(!show)} className={`rfgersdf ${show? 'dfssdf' : 'hjgtyu'}`} ></div>
    <div className={`ertegsdfweasf ${show? 'gfhgfe' : 'sdfsdf'}`} >
    <div className="back2"></div>
    <img src={wsdfert} className="dfgersd-afsfgdf" draggable="false"/>
    <span className="trhsdfw"></span>
    <img src={axcwerwegs} className="ertdfgdf" draggable="false"></img>
    <span className="rfhersdsf"></span>
    <img src={a34tfsd} className="dfgherfsdf" draggable="false"></img>
    <div className="sdfweregdf"></div>
    <img src={fgertsdfs} className="ghjfhdfgd"></img>
    <button className="gdhererdsf" aria-label="HypeSquad Brilliance" data-balloon-pos="up"></button>
    <img src={sdfgwed} className="sadfweasd"></img>
    <button className="dgwerwdf" aria-label="Active Developer" data-balloon-pos="up"></button>
    <img src={sgwesxc} className="sdfdsgwew"></img>
    <button className="dfgwerdf" aria-label="Assinante Nitro" data-balloon-pos="up"></button>
    <img src={dfwesdf} className="asdqwedasd"></img>
    <button className="sdfgwerwdf" aria-label="Impulsionando Servidor" data-balloon-pos="up"></button>
    <div className="asdqwesdg">
    <span className="sdfgweradf"></span>
    <span className="dfgwersadf">dark.dev</span>
    <div className="dgwertgsd">Conexões do usuário</div>
    <div class="fdgwersdf"></div>
    <div class="dfgdfgdfg"></div>
    <div type="button"onClick={()=>setShow(!show)} className={`teste ${show? 'inactive' : 'active'}`} >
    <img src={asdewg} className="sgwewdf"></img>
    </div>
    <div type="button" class="dfgweasdf"></div>
    <img src={wersdfs} className="asdvgbfges" draggable="false"></img>
    <div className="dfhdfsfsc">dark.dev</div>
    <img src={dfgdfhertdf} className="dfgersdfasdf" draggable="false"></img>
    <a href="https://discord.com/users/247726496435666944/" target="_blank">
    <img src={adfwertsdfg} className="asdqwerfhgds" draggable="false"></img>
    </a>

    <div type="button" class="asdffhdsdf"></div>
    <div className="fgdfsdf">Twitter</div>
    <a href="https://twitter.com/D4rkksA" target="_blank">
    <img src={adfwertsdfg} className="asdfwergehxf" draggable="false"></img>
    </a>
    
    <div type="button" class="fdgdgsdf"></div>
    <img src={hnasdhah} className="asdfsgdf" draggable="false"></img>
    <div className="sdfsdfsh">Instagram</div>
    <a href="https://www.instagram.com/gustavo.lass/" target="_blank">
    <img src={adfwertsdfg} className="erdfgdsdsd"></img>
    </a>

    <div type="button" class="sdgweergdfdf"></div>
    <img src={hdvfghjasdf} className="sgwewesg" draggable="false"></img>
    <div className="dfghsdfsdsv">Spotify</div>
    <a href="https://open.spotify.com/user/d4rkks" target="_blank">
    <img src={adfwertsdfg} className="sdfsdgsfg"></img>
    </a>
    </div>
    </div>
    </div>
    </header>
);
};
export default Card;